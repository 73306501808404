<template>
  <!-- v-touch="{
      left: () => $router.go(1),
      right: () => $router.push(-1)
    }" -->
  <v-row
    align="center"
  >
    <v-col>
      <app-card>
        <v-card-text>
          <h1>
            {{ $t("message.about_this_site") }}
          </h1>
          <br>
          <v-divider />
          <br>
          <h4>
            {{ $t("message.help_1") }}<br>
            {{ $t("message.help_2") }}
          </h4>

          <material-alert
            color="info"
            dark
          >
            <h3> {{ $t("message.help_44") }} </h3>
          </material-alert>
          <div class="iframe-container">
            <iframe
              src="https://docs.google.com/presentation/d/e/2PACX-1vRGB4upQk-LCvmAj_UxI__GRIc9OgZX-OGxO5XCZnXp1RdSNmSxAw_YKgO9x1NNVN3Glpf6HZpcDWzg/embed?start=false&loop=false&delayms=3000"
              allowfullscreen="true"
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
            />
          </div>
          <material-alert
            color="info"
            dark
          >
            <h3> {{ $t("message.help_7") }} </h3>
          </material-alert>
          <h4>
            {{ $t("message.help_8") }}<br>
            {{ $t("message.help_9") }}<br>
            <br>
          </h4>

          <material-alert
            color="info"
            dark
          >
            <h3> {{ $t("message.help_16") }} </h3>
          </material-alert>
          <h4>
            {{ $t("message.help_17") }}<br>
            {{ $t("message.help_18") }}<br>
            {{ $t("message.help_19") }}<br>
            {{ $t("message.help_20") }}<br>
            {{ $t("message.help_21") }}<br>
          </h4>
        </v-card-text>
      </app-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'NotificationsView',
    metaInfo: {
      title: 'Help',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keywords', content: 'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden' },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://ff14eden.work' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        { name: 'description', content: 'FFXIV Housing Edenはファイナルファンタジー14の最新ハウジング情報を世界レベルでお届けするサイトです。今話題のツイートはもちろん、バズツイート、ハウジンガー名鑑、創作家具レシピまで網羅しています。' },
        { property: 'og:image', content: 'https://ff14eden.work/twitter_card.jpg?3' },
      ],
    },
    data () {
      return {
        // sns拡散
        sns: {
          twitter: 'https://twitter.com/intent/tweet?hashtags=HousingEden',
        },
      }
    },
  }
</script>

<style>
.iframe-container {
  position: relative;
  width: 100%;
  max-width: 1400px;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
